<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import pace from 'pace-js';

// @ is an alias to /src
export default {
  name: "Login",
  components: {
  },
  data() {
    return {
    };
  },
  created() {
    pace.start({
      document: false
    });
  },
  methods: {
  },
};
</script>
<style scoped lang="scss">
</style>
